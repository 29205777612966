import { Button } from "antd";
import Style from "../../styles/output.module.css";
import { useState } from "react";
import { resultType } from "../../@types/user";
import Chart  from "react-apexcharts";
import StringOptimizer from "../../utils/StringOptimizer";
import ScrollerWrapper from "../scrollerWrapper";


type propType ={
    mapper : {name:string,data:string[]}[],
    myresult: {
        name:string,
        output:{
            name:string,
            data:{
                name:string,
                type:string,
                value:number
            }[]
        }[]
    
    }[][]
}

const GraphScreen = ({mapper,myresult}:propType)=>{

    const [graphType,setGraphType] = useState<any>("bar");

    const graphArr = [
        {value:"bar",label:"Bar Graph"},
        {value:"area",label:"Area Graph"}
    ]

    return(
        <div className="chartBoxCnt printable">
        {
            myresult.map((aelem,aind)=>(
                <ScrollerWrapper>
        {
            mapper.map((elem,index)=>(

                <div key={Math.random()} className="chartBox">

                <p className="graphTitle">{elem.name}</p>
                <Chart
                    options={{
                        chart: {
                        id: elem.name+"inner",
                        toolbar:{
                            show:true
                        },
                        background:"#fff"
                        },
                        xaxis: {
                        categories: elem.data.map(ielem=> (StringOptimizer.nameToGraphicalName(ielem)) ),
                        labels:{
                            show:true,
                            hideOverlappingLabels:false,
                            rotate:-35,
                            rotateAlways:true,
                            minHeight:150,
                            style:{
                                fontSize:"12px"
                            }
                        }
                        },
                        grid:{
                            padding:{
                                left:30
                            },
                            row:{
                                colors:['#f27c142a','#f5e31d20']
                            }
                        },
                        colors: ['#6d597a', '#d8315b','#9C27B0','#E91E63','#F44336']
                    }}
                    series={
                        aelem.map((resElem,resInd)=>(
                            {
                                name : resElem.name,
                                data : resElem.output[index].data.map((ielem,iind)=> (parseFloat(ielem.value?.toFixed(2))) )
                            }
                        ))
                    }
                    type={graphType}
                    width="1351"
                    
                    />

                </div>
                ))
        }
        </ScrollerWrapper>
            ))
        }
        </div>
    )
}

export default GraphScreen;