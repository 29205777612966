import { Button } from "antd";
import Style from "../../styles/output.module.css";
import { filteredResulttype, scenarioType, submitScenarioType } from "../../@types/user";
import React, { useEffect, useState } from "react";
import { AttachMoneyOutlined, CurrencyRupee } from "@mui/icons-material";
import NumberFormat from "../../utils/NumberFormat";
import GraphScreen from "./GraphScreen";
import WrapperOutput from "./WrapperOutput";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import ScrollerWrapper from "../scrollerWrapper";
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import BarChartIcon from '@mui/icons-material/BarChart';
import PrintBtn from "../PrintBtn";
import DeltaTableScreen from "./DeltaTableScreen";
import CurrencyIconRenderer from "../CurrencyIconRendered";


type comparedScreenPropType={
    result:filteredResulttype[],
    myCurrency:string,
    setCompareEnabled:(res:boolean)=> void,
    mapper:{name:string,data:string[]}[],
    submitableScenario:submitScenarioType[]
}


const ComparedScreen = ({result,myCurrency,setCompareEnabled,mapper,submitableScenario}:comparedScreenPropType)=>{

    const allScenario = useSelector((state: RootState) => state.calculator.tabs[state.calculator.activeTab].scenario);
    const [myresult,setMyresult] = useState<any[]>([]);
    const [forwardResult,setForwardResult] = useState<any[]>([]);

    const [prep,setPrep] = useState<{
        name:string,
        data:{
            name:string,
            value:string[]
        }[],
        servicableYear:number,
        yearsRemaining:number
    }[][]>([]);

    const [showGraph,setShowGraph] = useState(false);
    const [showTable,setShowTable] = useState(false);
    const [deltaTable,setDeltaTable] = useState(false);


    useEffect(()=>{

        window.scrollTo(0,0);
        if(myresult.length < 1)
        setMyresult([...result]);

    },[])

    useEffect(()=>{
        if(myresult.length < 1) return;


        let x:filteredResulttype[]= [[]];
        myresult.forEach(element => {
            x[0].push(element[element.length -1]);
        });

        setForwardResult(x);
        // console.log("x",x);

    },[myresult])

    useEffect(()=>{

        if(forwardResult.length < 1) return;


        let localPrep : {
            name:string,
            data:{
                name:string,
                value:string[]
            }[],
            servicableYear:number,
            yearsRemaining:number
        }[][] = [];

        forwardResult.forEach((elem,index) => {

            let x:{
                name:string,
                data:{name:string,value:string[]}[],
                servicableYear:number,
                yearsRemaining:number
            }[] = [];

            elem.forEach((element:any,ind:number) => {
    
                let inflation = element.cashFlow.inflation;
                let tax_rate = element.cashFlow.rawTax;
                let wacc = element.cashFlow.rawWacc;
                let volume = element.cashFlow.volume;
                let month = element.cashFlow.tables.atcc.length;
    
                let Volume:string[] = Array.from({length:month}).map((elem,ind)=> ind === 0 || ind > element.cashFlow.greatestTimeFrame? "0" : (volume ? volume.toString() : "0") );
                let Inflation:string[] = Array.from({length:month}).map((elem,ind)=> ind === 0 || ind > element.cashFlow.greatestTimeFrame? "0" : (inflation ? inflation.toString() : "0") );
                let Lta:string[] = Array.from({length:month}).map((elem,ind)=> ("0") );
                let Tax:string[] = Array.from({length:month}).map((elem,ind)=> (ind > element.cashFlow.greatestTimeFrame? "0" : (tax_rate ? tax_rate.toString() : "0") ) );
                let Wacc:string[] = Array.from({length:month}).map((elem,ind)=> (ind > element.cashFlow.greatestTimeFrame? "0" : (wacc ? wacc.toString() : "0" ) ) );
    
                x = [
                    ...x,
                    {
                        name:element.name,
                        data:[
                            {
                                name:'Volume',
                                value:Volume
                            },
                            {
                                name:'Inflation',
                                value:Inflation
                            },
                            {
                                name:'Long term agreement',
                                value:Lta
                            },
                            {
                                name:'Tax Rate',
                                value:Tax
                            },
                            {
                                name:'WACC',
                                value:Wacc
                            }
                        ],
                        servicableYear: element.cashFlow.greatestTimeFrame,
                        yearsRemaining:element.cashFlow.yearsRemaining
                    }
                ]
                
            });
        
            localPrep.push(x);
        });

        setPrep(localPrep);


    },[forwardResult])


    useEffect(()=>{

        console.log("Compared prep",prep);

    },[prep])

    return(
        <div className="outputScreen">
        {
            (!showGraph && !showTable) &&
            <WrapperOutput
            name={["Home","Output","Delta"]}
            onBackPress={()=> setCompareEnabled(false)}
            leftButtons={[
                <PrintBtn />,
                <Button
                type="text" 
                className={`${Style.rtBtn}`}
                onClick={()=> setShowTable(true) }
                >
                    <MonetizationOnIcon />
                    Delta Cash Flows
                </Button>,                
                <Button 
                type="text" 
                className={`${Style.rtBtn}`}
                onClick={()=> setShowGraph(true) }
                >
                    <BarChartIcon />
                    Delta Graph
                </Button>
            ]}
            >
                <div className={`${Style.resHolder} printable`}>
                {
                    myresult.map((aelem,aind)=>(
                    <ScrollerWrapper>
                    <>

                    {
                        mapper.map((outerElem,outerInd)=>(
                            <div key={outerInd} className={Style.groupcntwrapper}>
                                <div className={Style.groupcnt}>
                                    <div className={Style.gcHeader}>
                                        <p>{outerElem.name}</p>
                                    </div>

                                    <div className={`${Style.gcContent}`}>
                                        <table className={Style.inpTile}>

                                        <tr>

                                            <th>
                                                                               
                                            </th>
                                            {
                                                aelem.map((elem:any,ind:number)=>(
                                                    <th 
                                                    key={`fieldsname-${outerInd}${ind}`}
                                                    >
                                                        <div className={Style.scenarioSelector}>
                                                            {
                                                                (ind === aelem.length-1) ?
                                                                "Delta"
                                                                :
                                                                elem.name
                                                            }
                                                        </div>
                                                    </th>
                                                ))
                                            }
                                        </tr>

                                        {
                                            outerElem.data.map((innerElem,innerInd)=>(
                                                <React.Fragment key={`fieldsname-${outerInd}${innerInd}`}>
                                                    {
                                                        ((outerInd === 0 && innerInd === 1) || (outerInd === 0 && innerInd === 2)) ?
                                                        <></>
                                                        :
                                                        <tr 
                                                    >
                                                        <td>
                                                            <div className={Style.resultInnerLeft}>
                                                                {innerElem}
                                                            </div>
                                                        </td>
                                                    {
                                                        aelem.map((reselem:any,resind:number)=>(
                                                            <td
                                                            key={`fieldsname-${outerInd}${resind}`}
                                                            >
                                                                <div className={`
                                                                inputCnt
                                                                ${Style.resultBox} 
                                                                ${resind === aelem.length-1 && Style.deltaTd}
                                                                ${
                                                                    (outerInd === 0 && innerInd ===0 && resind !== aelem.length-1) &&
                                                                    ((
                                                                        (reselem.output[outerInd].data[2].value != 0 && reselem.output[outerInd].data[innerInd].value < reselem.output[outerInd].data[2].value) || 
                                                                        (reselem.output[outerInd].data[1].value != 0 && reselem.output[outerInd].data[innerInd].value > reselem.output[outerInd].data[1].value )
                                                                    )
                                                                        ?
                                                                        "danger"
                                                                        :
                                                                        "green"
                                                                    )
                                                                }
                                                                ${
                                                                    (
                                                                        (outerInd === 0 && innerInd ===7 && resind !== aelem.length-1) ||
                                                                        (outerInd === 0 && innerInd ===8 && resind !== aelem.length-1) ||
                                                                        (outerInd === 0 && innerInd ===10 && resind !== aelem.length-1) ||
                                                                        (outerInd === 0 && innerInd ===12 && resind !== aelem.length-1) ||
                                                                        (outerInd === 1 && innerInd ===5 && resind !== aelem.length-1) ||
                                                                        (outerInd === 1 && innerInd ===6 && resind !== aelem.length-1) ||
                                                                        (outerInd === 1 && innerInd ===8 && resind !== aelem.length-1) ||
                                                                        (outerInd === 2 && innerInd ===5 && resind !== aelem.length-1) ||
                                                                        (outerInd === 2 && innerInd ===6 && resind !== aelem.length-1) ||
                                                                        (outerInd === 2 && innerInd ===8 && resind !== aelem.length-1) 
                                                   
                                                                    ) &&
                                                                    (
                                                                        reselem.output[outerInd].data[innerInd].value < 0 ? "danger" : "green"
                                                                    )
                                                                } 
                                                                ${
                                                                    
                                                                    (outerInd === 0 && (innerInd === 9 || innerInd === 11) && resind !== aelem.length-1) && 
                                                                    ((((reselem.output[outerInd].data[innerInd].value/reselem.output[0].data[0].value) * 100 > reselem.cashFlow.atrosRate) ) ?
                                                                    "green"
                                                                    :
                                                                    "danger")
                                                                }
                                                                ${
                                                                    
                                                                    ((outerInd === 1 || outerInd === 2) && (innerInd === 7 || innerInd === 9) && resind !== aelem.length-1) && 
                                                                    ((((reselem.output[0].data[innerInd+2].value/reselem.output[0].data[0].value) * 100 > reselem.cashFlow.atrosRate) ) ?
                                                                    "green"
                                                                    :
                                                                    "danger")
                                                                }

                                                                ${
                                                                    
                                                                    (outerInd === 2 && innerInd ===10 && resind !== aelem.length-1) &&
                                                                    (reselem.output[outerInd].data[innerInd].value < reselem.cashFlow.irrRate ? "danger" : "green")
                                                                    
                                                                }
                                                                ${
                                                                    
                                                                    (outerInd === 3 && innerInd ===0 && resind !== aelem.length-1) &&
                                                                    (
                                                                        (
                                                                            reselem.output[outerInd].data[innerInd].value > reselem.cashFlow.ot_above_x && 
                                                                            reselem.output[outerInd].data[innerInd].value <= reselem.cashFlow.ot_above_y  
                                                                        )? "danger" :
                                                                        (
                                                                            reselem.output[outerInd].data[innerInd].value > reselem.cashFlow.ot_above_y && 
                                                                            reselem.output[outerInd].data[innerInd].value <= 8500
                                                                        ) ? "superdanger" : 
                                                                        (
                                                                            reselem.output[outerInd].data[innerInd].value > 8500 && "notpossiblevalue"
                                                                        )
                                                                    )
                                                                    
                                                                }

                                                                `}>
                                                                    {
                                                                        reselem.output[outerInd].data[innerInd].type === "currency" &&
                                                                        <CurrencyIconRenderer type={myCurrency} />
                                                                    }
                                                                    
                                                                    {
                                                                        (reselem.output[outerInd].data[innerInd].value) ? 
                                                                            (reselem.output[outerInd].data[innerInd].value != 11011) ?
                                                                                myCurrency === "INR" ?
                                                                                    NumberFormat.toINDstandard((reselem.output[outerInd].data[innerInd].value).toFixed(2))
                                                                                :
                                                                                    NumberFormat.toUSstandard((reselem.output[outerInd].data[innerInd].value).toFixed(2))
                                                                                    
                                                                            :
                                                                                <AllInclusiveIcon />
                                                                        :
                                                                            0
                                                                    }
                                                                   
                                                                    {
                                                                        (
                                                                            reselem.output[outerInd].data[innerInd].type === "perc" && 
                                                                            reselem.output[outerInd].data[innerInd].value != 11011 
                                                                        ) &&
                                                                        <span className="signs">
                                                                        %
                                                                        </span>
                                                                    }
                                                                    </div>
                                                                
                                                            </td>
                                                        ))
                                                    }
                                                    </tr>
                                                    }
                                                </React.Fragment>
                                            ))
                                        }

                                        </table>
                                    </div>

                                </div>
                            </div>
                        ))
                    }

                    </>
                </ScrollerWrapper>))
                }

                
                </div>
            </WrapperOutput>
        }
        {
            
            (showGraph) &&
            <WrapperOutput
            onBackPress={()=> setShowGraph(false) }
            name={["Home","Output","Delta","Graphs"]}
            leftButtons={[
                <PrintBtn />
            ]}
            >
                <GraphScreen 
                mapper={mapper}
                myresult={myresult.map(el=> [el[el.length -1]] )}
                />
            </WrapperOutput>
            
            
        }
        {
            showTable &&
            <WrapperOutput
            onBackPress={()=> setShowTable(false) }
            name={["Home","Output","Delta","Cash Flows"]}
            leftButtons={[
                <PrintBtn />
            ]}
            >
                <DeltaTableScreen
                    myresult={forwardResult}
                    prep={prep}
                    setPrep={setPrep}
                    submitableScenario={submitableScenario}
                />
            </WrapperOutput>
        }
        </div>
    )
}

export default ComparedScreen;