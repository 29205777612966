import { useEffect, useState } from 'react';
import { resultType } from '../../@types/user';

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import NumberFormat from '../../utils/NumberFormat';

import CreditScoreIcon from '@mui/icons-material/CreditScore';
import { ExpandOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';


dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);



type loanType = {
    result:resultType,
    setSelectedLoanResult:any,
    myCurrency:string,
    selectedLoan:string,
    setSelectedLoan:(val:string | ((prev:string)=> string) )=> void
}
export default function LoanScreen({result,myCurrency,selectedLoan,setSelectedLoan,setSelectedLoanResult}: loanType) {

    const activeTabSce = useSelector((state: RootState) => state.calculator.activeTabScenarios);

    const [mainLoan,setMainLoan] = useState<{
        sceName:string,
        years:{
            yearName:string,
            interests:any,
            total:number,
            yearConstant : number

        }[],
        nonAmmort:{
            factoringLoan:number[],
            lineOfCredit:number[],
            simpleInterest:number[]
        },
        allJobNames:string[]
    }[]>([]);

    const [nonAmmortLoan,setNonAmmortLoan] = useState([]);

    // Preparing Mappable Array from the Data structure sent by backend
    useEffect(()=>{

        let myMainLoan:{
            sceName:string,
            years:{
                yearName:string,
                interests:any
                total:number,
                yearConstant : number
    
            }[],
            nonAmmort:{
                factoringLoan:number[],
                lineOfCredit:number[],
                simpleInterest:number[]
            },
            allJobNames:string[]
        }[] = [];

        result.forEach((oelem,oind)=>{

            let keys = Object.keys(oelem.output.loans);
            let loanPrep:any[] = [];
            keys.forEach(element => {
                loanPrep.push(oelem.output.loans[element]);
            });
            
            let lowestLoanYear = parseInt(dayjs().format("YYYY")) + 10000;
            let highestLoanYear = 0;
            let ob:any = {};

            loanPrep.forEach((element:any,index:number)=>{

                element.forEach((elem:any,ind:number) => {
    
                    elem.forEach((ielem:any,iind:number) => {
    
                        ielem.data.forEach((nelem:any,nind:number) => {
    
                            // Finding Highest Loan Year
                            if(nelem.loanYearConstant > highestLoanYear){
                                highestLoanYear = nelem.loanYearConstant;
                            }
    
                            // Finding Lowest Loan Year
                            if(nelem.loanYearConstant < lowestLoanYear){
                                lowestLoanYear = nelem.loanYearConstant;
                            }

                            // Separating loans according to their Loan Year with loan year as the key of the object.
                            if(ob[nelem.loanYearConstant]){

                                if(ob[nelem.loanYearConstant][keys[index]]){
                                    (ob[nelem.loanYearConstant][keys[index]].interest += nelem.totalData.interest);
                                    ob[nelem.loanYearConstant][keys[index]].totalLoanCount++;
                                }
                                else{
                                    ob[nelem.loanYearConstant]={
                                        ...ob[nelem.loanYearConstant],
                                        [keys[index]] : {
                                            fiscalStart : nelem.fiscalStart,
                                            fiscalEnd : nelem.fiscalEnd,
                                            interest : nelem.totalData.interest,
                                            loanYearConstant : nelem.loanYearConstant,
                                            totalLoanCount : 1
                                        }
                                    };
                                }
                            }
                            else{
                                ob[nelem.loanYearConstant]={
                                    [keys[index]] : {
                                        fiscalStart : nelem.fiscalStart,
                                        fiscalEnd : nelem.fiscalEnd,
                                        interest : nelem.totalData.interest,
                                        loanYearConstant : nelem.loanYearConstant,
                                        totalLoanCount : 1
                                    }
                                };
                            }
    
                        });
                    });
                    
                });
            });

            
          
            // let newKeys = Object.keys(ob); // getting all loan year keys in an array;

            let currentYear = dayjs().format("YYYY");
            let newKeys = [parseInt(currentYear)];
          
  

            // Transforming Data to the type of mainLoan state variable for each Scenario
            let newOb = newKeys.map((elem,ind)=>{
                let innerKey:any[] = []; 
                if(!ob[elem]){
                
                    return  {
                        yearName : `${currentYear}`,
                        interests: {},
                        total : 0,
                        yearConstant : currentYear
                    }
                }
            
                innerKey = Object.keys(ob[elem]);

                let tot = 0;
                let inLoans:any = {};
                
                innerKey.forEach((element,index) => {
                    tot += ob[elem][element].interest;
                    inLoans[element] = {
                        value : ob[elem][element].interest,
                        count : ob[elem][element].totalLoanCount
                    }
                });

                return  {
                    yearName : `(${ob[elem][innerKey[0]].fiscalStart}) - (${ob[elem][innerKey[0]].fiscalEnd})`,
                    interests: inLoans,
                    total : parseFloat(tot.toFixed(2)),
                    yearConstant : ob[elem][innerKey[0]].loanYearConstant
                }
            });



            // Pushing Data for Each Scenario
            myMainLoan.push({
                sceName : oelem.name,
                years : newOb,
                nonAmmort : oelem.output.nonAmmortLoans,
                allJobNames : activeTabSce.filter(elem=> elem.name === oelem.name )[0].jobs.map(el=> el.name)
            });

        });

        console.log("main loan", mainLoan);


        setMainLoan(myMainLoan); // Setting our mainloan state variable
      
    },[]);


    useEffect(()=>{
        console.log("OOb", mainLoan);
    },[mainLoan]);

  

    const loanclickHandler = (sceName:string,loanName:string)=>{

        const selec = result.filter((elem)=> elem.name === sceName );

        setSelectedLoanResult(selec);
        setSelectedLoan(loanName);
    }

  return (
    <div>
        {
            mainLoan.map((element,index)=>(
                <div className='mainLoanContainer'>
                    <p className='loanScenarioHeader'>{element.sceName}</p>
                    <div>
                        <div className='mainLoanLeft'>
                            <p>Ammortization Loans</p>
                            <div>
                            {
                                element.years.length > 0 ?
                                element.years.map((elem,ind)=>(
                                    <div
                                    key={`mo${ind}`}
                                    >

                                        <TableContainer sx={{padding:2,width:"auto",maxWidth:"500px"}} component={Paper}>
                                            {/* <p></p> */}
                                            <Table aria-label="simple table">
                                            <TableHead>
                                            <TableRow>
                                                <TableCell align="left">{elem.yearName}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="left">Loan Type</TableCell>
                                                <TableCell align="right">Total Interest</TableCell>
                                            </TableRow>
                                            </TableHead>
                                            <TableBody>
                                        
                                        
                                                <TableRow 
                                                className='hoveredRow'
                                                sx={{ '&:last-child td, &:last-child th': { border: 0} }}
                                                >
                                                    <TableCell align="left">Senior Debt Loan <div className='loanCatCount'>x{elem.interests["seniorDebtLoan"]?.count || 0}</div></TableCell>
                                                    <TableCell align="right">{elem.interests["seniorDebtLoan"] ? NumberFormat.convertAccordingCurrency(myCurrency,elem.interests["seniorDebtLoan"].value) : 0}</TableCell>
                                                    <TableCell align="center">
                                                        <IconButton 
                                                        sx={{width:25,height:25}}
                                                        onClick={()=> loanclickHandler(element.sceName,"seniorDebtLoan") }
                                                        >
                                                            <ExpandOutlined style={{fontSize:"16px",fontWeight:"bold"}} />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>

                                                <TableRow 
                                                className='hoveredRow'
                                                sx={{ '&:last-child td, &:last-child th': { border: 0} }}
                                                >
                                                    <TableCell align="left">Equipment Loan <div className='loanCatCount'>x{elem.interests["equipmentLoan"]?.count || 0}</div></TableCell>
                                                    <TableCell align="right">{elem.interests["equipmentLoan"] ? NumberFormat.convertAccordingCurrency(myCurrency,elem.interests["equipmentLoan"].value) : 0}</TableCell>
                                                    <TableCell align="center">
                                                        <IconButton 
                                                        sx={{width:25,height:25}}
                                                        onClick={()=> loanclickHandler(element.sceName,"equipmentLoan") }
                                                        >
                                                             <ExpandOutlined style={{fontSize:"16px",fontWeight:"bold"}} />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>

                                                <TableRow 
                                                className='hoveredRow'
                                                sx={{ '&:last-child td, &:last-child th': { border: 0} }}
                                                >
                                                    <TableCell align="left">Mezzanine Lending <div className='loanCatCount'>x{elem.interests["mazzanineLoan"]?.count || 0}</div></TableCell>
                                                    <TableCell align="right">{elem.interests["mazzanineLoan"] ? NumberFormat.convertAccordingCurrency(myCurrency,elem.interests["mazzanineLoan"].value ) : 0}</TableCell>
                                                    <TableCell align="center">
                                                        <IconButton 
                                                        sx={{width:25,height:25}}
                                                        onClick={()=> loanclickHandler(element.sceName,"mazzanineLoan") }
                                                        >
                                                             <ExpandOutlined style={{fontSize:"16px",fontWeight:"bold"}} />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>

                                                <TableRow 
                                                className='hoveredRow'
                                                sx={{ '&:last-child td, &:last-child th': { border: 0} }}
                                                >
                                                    <TableCell align="left">Micro Loan <div className='loanCatCount'>x{elem.interests["microLoan"]?.count || 0}</div></TableCell>
                                                    <TableCell align="right">{elem.interests["microLoan"] ? NumberFormat.convertAccordingCurrency(myCurrency,elem.interests["microLoan"].value) : 0}</TableCell>
                                                    <TableCell align="center">
                                                        <IconButton 
                                                        sx={{width:25,height:25}}
                                                        onClick={()=> loanclickHandler(element.sceName,"microLoan") }
                                                        >
                                                             <ExpandOutlined style={{fontSize:"16px",fontWeight:"bold"}} />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>

                                                <TableRow 
                                                className='hoveredRow'
                                                sx={{ '&:last-child td, &:last-child th': { border: 0} }}
                                                >
                                                    <TableCell align="left">Personal Loan <div className='loanCatCount'>x{elem.interests["personalLoan"]?.count || 0}</div></TableCell>
                                                    <TableCell align="right">{elem.interests["personalLoan"] ? NumberFormat.convertAccordingCurrency(myCurrency,elem.interests["personalLoan"].value ) : 0}</TableCell>
                                                    <TableCell align="center">
                                                        <IconButton 
                                                        sx={{width:25,height:25}}
                                                        onClick={()=> loanclickHandler(element.sceName,"personalLoan") }
                                                        >
                                                             <ExpandOutlined style={{fontSize:"16px",fontWeight:"bold"}} />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>

                                                <TableRow 
                                                className='hoveredRow'
                                                sx={{ '&:last-child td, &:last-child th': { border: 0} }}
                                                >
                                                    <TableCell align="left">Real Estate Loan <div className='loanCatCount'>x{elem.interests["realEstateLoan"]?.count || 0}</div></TableCell>
                                                    <TableCell align="right">{elem.interests["realEstateLoan"] ? NumberFormat.convertAccordingCurrency(myCurrency,elem.interests["realEstateLoan"].value ) : 0}</TableCell>
                                                    <TableCell align="center">
                                                        <IconButton 
                                                        sx={{width:25,height:25}}
                                                        onClick={()=> loanclickHandler(element.sceName,"realEstateLoan") }
                                                        >
                                                             <ExpandOutlined style={{fontSize:"16px",fontWeight:"bold"}} />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>

                                                <TableRow 
                                                className='hoveredRow'
                                                sx={{ '&:last-child td, &:last-child th': { border: 0} }}
                                                >
                                                    <TableCell align="left">SBA Loan <div className='loanCatCount'>x{elem.interests["sbaLoan"]?.count || 0}</div></TableCell>
                                                    <TableCell align="right">{elem.interests["sbaLoan"] ? NumberFormat.convertAccordingCurrency(myCurrency,elem.interests["sbaLoan"].value) : 0}</TableCell>
                                                    <TableCell align="center">
                                                        <IconButton 
                                                        sx={{width:25,height:25}}
                                                        onClick={()=> loanclickHandler(element.sceName,"sbaLoan") }
                                                        >
                                                             <ExpandOutlined style={{fontSize:"16px",fontWeight:"bold"}} />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                                
                                                <TableRow 
                                                sx={{ '&:last-child td, &:last-child th': { border: 0} }}
                                                >
                                                    <TableCell align="left">Total Annual Interest</TableCell>
                                                    <TableCell align="right">{NumberFormat.convertAccordingCurrency(myCurrency,elem.total)}</TableCell>
                                                </TableRow>
                                            </TableBody>

                                            </Table>
                                            
                                        </TableContainer>
                                        
                                    </div>
                                ))
                                :
                                <div className='noLoanDataCnt'>
                                    <CreditScoreIcon />
                                    <p>No Loan Data found in <b>{element.sceName} Scenario</b></p>
                                </div>
                            }

                            </div>

                        </div>
                        <div className='mainLoanRight'>
                            <p>Non - Ammortization Loans</p>
                            <div>
                            {
                                element.years.length > 0 ?
                                element.years.map((elem,ind)=>(
                                    <div
                                    key={`mor${ind}`}
                                    style={{
                                        width:"100%"
                                    }}
                                    >

                                        <TableContainer sx={{padding:2,width:600}} component={Paper}>
                                            {/* <p></p> */}
                                            <Table aria-label="simple table">
                                            <TableHead>

                                                <TableRow>
                                                    <TableCell align="left">{elem.yearName}</TableCell>
                                                </TableRow>

                                                <TableRow>
                                                    <TableCell align="left">Loan Type</TableCell>
                                                    {
                                                        element.allJobNames.map((el,ind)=>(
                                                            <TableCell align="right" key={`jobxyz${ind}`}>{el}</TableCell>
                                                        ))
                                                    }
                                                    <TableCell align="right">Total Interest</TableCell>
                                                </TableRow>
                                                
                                            </TableHead>
                                            <TableBody>
                                        


                                                <TableRow 
                                                className='hoveredRow'
                                                sx={{ '&:last-child td, &:last-child th': { border: 0} }}
                                                >
                                                     <TableCell align="left">Factoring Amount <div className='loanCatCount'>x{element.nonAmmort['factoringLoan'].filter(pl=> pl !== 0).length}</div></TableCell>
                                                    {
                                                        element.allJobNames.map((lr,lrind)=>(
                                                            <TableCell align="right">
                                                                {
                                                                element.nonAmmort['factoringLoan'][lrind] ?
                                                                NumberFormat.convertAccordingCurrency(myCurrency,element.nonAmmort['factoringLoan'][lrind])
                                                                :
                                                                0
                                                                }
                                                            </TableCell>
                                                        ))
                                                    }
                                                    <TableCell align='right'>
                                                         {NumberFormat.convertAccordingCurrency(myCurrency,parseFloat((element.nonAmmort['factoringLoan'].reduce((a,b)=> a+b )).toFixed(2)))} 
                                                    </TableCell>

                                                </TableRow>

                                                <TableRow 
                                                className='hoveredRow'
                                                sx={{ '&:last-child td, &:last-child th': { border: 0} }}
                                                >
                                                    
                                                    <TableCell align="left">Line of credit Loan <div className='loanCatCount'>x{element.nonAmmort['lineOfCredit'].filter(pl=> pl !== 0).length}</div></TableCell>
                                                    {
                                                        element.allJobNames.map((lr,lrind)=>(
                                                            <TableCell align="right">
                                                                {
                                                                element.nonAmmort['lineOfCredit'][lrind] ?
                                                                NumberFormat.convertAccordingCurrency(myCurrency,element.nonAmmort['lineOfCredit'][lrind])
                                                                :
                                                                0
                                                                }
                                                            </TableCell>
                                                        ))
                                                    }
                                                    <TableCell align='right'>
                                                        {NumberFormat.convertAccordingCurrency(myCurrency,parseFloat((element.nonAmmort['lineOfCredit'].reduce((a,b)=> a+b )).toFixed(2)))}
                                                    </TableCell>
                                                </TableRow>


                                                <TableRow 
                                                className='hoveredRow'
                                                sx={{ '&:last-child td, &:last-child th': { border: 0} }}
                                                >
                                                    <TableCell align="left">Simple Interest Loan <div className='loanCatCount'>x{element.nonAmmort['simpleInterest'].filter(pl=> pl !== 0).length}</div></TableCell>
                                                    {
                                                        element.allJobNames.map((lr,lrind)=>(
                                                            <TableCell align="right">
                                                                {
                                                                element.nonAmmort['simpleInterest'][lrind] ?
                                                                NumberFormat.convertAccordingCurrency(myCurrency,element.nonAmmort['simpleInterest'][lrind])
                                                                :
                                                                0
                                                                }
                                                            </TableCell>
                                                        ))
                                                    }
                                                    <TableCell align='right'>
                                                        {NumberFormat.convertAccordingCurrency(myCurrency,parseFloat((element.nonAmmort['simpleInterest'].reduce((a,b)=> a+b )).toFixed(2)))}
                                                    </TableCell>

                                                </TableRow>

                                                
                                                <TableRow 
                                                sx={{ '&:last-child td, &:last-child th': { border: 0} }}
                                                >
                                                    <TableCell align="left">Total Annual Interest</TableCell>
                                                    {
                                                        element.allJobNames.map(()=> <TableCell align="left"></TableCell>)
                                                    }
                                                    <TableCell align="right">{
                                                    NumberFormat.convertAccordingCurrency(myCurrency,(
                                                        element.nonAmmort.factoringLoan.reduce((a,b)=> a+b) +
                                                        element.nonAmmort.lineOfCredit.reduce((a,b)=> a+b) +
                                                        element.nonAmmort.simpleInterest.reduce((a,b)=> a+b) 
                                                    ))
                                                    }</TableCell>
                                                </TableRow>
                                            </TableBody>

                                            </Table>
                                            
                                        </TableContainer>
                                        
                                    </div>
                                ))
                                :
                                <div className='noLoanDataCnt'>
                                    <CreditScoreIcon />
                                    <p>No Loan Data found in <b>{element.sceName} Scenario</b></p>
                                </div>
                            
                            }

                            </div>

                        </div>
                    </div>

                </div>
            ))
        }
      
    </div>
  )
}
