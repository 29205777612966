import { Button } from "antd";
import Style from "../../styles/output.module.css";
import { filteredResulttype, Payload, resultType, submitScenarioType } from "../../@types/user";
import React, { memo, useEffect, useState } from "react";
import NumberFormat from "../../utils/NumberFormat";
import GraphScreen from "./GraphScreen";
import TableScreen from "./TableScreen";
import WrapperOutput from "./WrapperOutput";
import { Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import ModalBox from "../modal";
import ComparedScreen from "./ComparedScreen";
import ScrollerWrapper from "../scrollerWrapper";
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import { CloseOutlined, RightOutlined } from "@ant-design/icons";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import BarChartIcon from '@mui/icons-material/BarChart';
import CompareIcon from '@mui/icons-material/Compare';
import Flynaut from "../branding/Flynaut";
import PrintBtn from "../PrintBtn";
import AnalysisScreen from "./AnalysisScreen";
import CurrencyIconRenderer from "../CurrencyIconRendered";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import LoanScreen from "./LoanScreen";
import SingleLoanScreen from "./SingleLoanScreen";
import LoanNameFormatter from "../../utils/LoanNameFormatter";


type outputScreenPropType={
    result:resultType,
    myCurrency:string,
    setResult:(res:resultType)=> void,
    submitableScenario:submitScenarioType[],
    payload:Payload,
    setPayload:(val:Payload)=> void,
    loading:boolean,
    reCalc:(val:Payload)=> void
}

const OutputScreen = ({result,myCurrency,setResult,submitableScenario}:outputScreenPropType)=>{

    const activeTabJobs = useSelector((state: RootState) => state.calculator.activeScenarioJobs); 
    const [myresult,setMyresult] = useState<any[]>([]);
    const [compared,setCompared] = useState<any[]>([]);
    const [prep,setPrep] = useState<{
        name:string,
        data:{
            name:string,
            value:string[]
        }[],
        servicableYear:number,
        yearsRemaining:number
    }[][]>([]);
    const [mapper,setMapper] = useState<{name:string,data:string[]}[]>([]);
    const [showGraph,setShowGraph] = useState(false);
    const [showTable,setShowTable] = useState(false);
    const [comparePopup,setComparePopup] = useState(false);
    const [showAnalysis,setShowAnalysis] = useState(false);
    const [showAmmort,setShowAmmort] = useState(false);
    const [compareList,setCompareList] = useState<{
        scenario:{name:string,key:string}[],
        base : {name:string,key:string}[],
        comparable : {name:string,key:string}[]
    }>({
        scenario:[],
        base : [],
        comparable : []
    });
    const [compareEnabled,setCompareEnabled] = useState(false);
    const [error,setError] = useState("");

    const [selectedLoan,setSelectedLoan] = useState("");
    const [selectedLoanResult,setSelectedLoanResult] = useState<resultType>([]);

    const onDragEnd = (result:any)=>{
        
        if (!result.destination) {
            return;
        }

        if(result.destination.droppableId === "base" && compareList.base.length === 1){
            return;
        }

        const source = result.source.droppableId === "scenarios" ? [...compareList.scenario || []] : result.source.droppableId === "base" ? [...compareList.base || []] : [...compareList.comparable || []];
        const desti = result.destination.droppableId === "scenarios" ? [...compareList.scenario  || []] : result.destination.droppableId === "base" ? [...compareList.base  || []] : [...compareList.comparable || []];

        if(JSON.stringify(source) === JSON.stringify(desti)){
            const [removed] = source.splice(result.source.index, 1);
            source.splice(result.destination.index, 0, removed);

            (result.source.droppableId === "scenarios") &&
            setCompareList(prev=> ({...prev,scenario: source}));
            
            (result.source.droppableId === "base") &&
            setCompareList(prev=> ({...prev,base: source}));
            
            (result.source.droppableId === "comparable") &&
            setCompareList(prev=> ({...prev,comparable: source}));
        }
        else{

            const [removed] = source.splice(result.source.index, 1);
            desti.splice(result.destination.index, 0, removed);

            (result.source.droppableId === "scenarios") &&
            setCompareList(prev=> ({...prev,scenario: source}));
            
            (result.source.droppableId === "base") &&
            setCompareList(prev=> ({...prev,base: source}));
            
            (result.source.droppableId === "comparable") &&
            setCompareList(prev=> ({...prev,comparable: source}));

            (result.destination.droppableId === "scenarios") &&
            setCompareList(prev=> ({...prev,scenario: desti}));
            
            (result.destination.droppableId === "base") &&
            setCompareList(prev=> ({...prev,base: desti}));
            
            (result.destination.droppableId === "comparable") &&
            setCompareList(prev=> ({...prev,comparable: desti}));
            
        }
        

        
        
    };

    const getFormatedYears = (years:number)=>{
        let x= years.toFixed(2).toString();
        let y = x.split(".");


        return { years:parseInt(y[0]), months:y.length > 1 ? parseFloat(y[1])/100 : 0};
    }

    useEffect(()=>{

        window.scrollTo(0,0);
        if(myresult.length < 1)
        setMyresult([result.map(elem=> ({name:elem.name,cashFlow:elem.output.cashflowTable,output:elem.output.result.map(el=> ({...el,data:el.data.map(ell=> ({...ell,value:parseFloat( ell.value?.toFixed(2) )}))}))}) )]);

        let mapper = result[0].output.result.map(elem=> ({name:elem.name,data:elem.data.map(el=> el.name)}) );
        setMapper(mapper);

        console.log("inner result", result);
        console.log("allj", activeTabJobs);

    },[])

    useEffect(()=>{

        if(myresult.length < 1) return;

        // console.log("Myresult",myresult);

        setCompareList({scenario: myresult[0].map((el:any,ind:number)=> ({key:`${ind}`,name:el.name}) ), base:[], comparable:[] });

        let localPrep : {
            name:string,
            data:{
                name:string,
                value:string[]
            }[],
            servicableYear:number,
            yearsRemaining:number
        }[][] = [];

        myresult.forEach((elem,index) => {

            let x:{
                name:string,
                data:{name:string,value:string[]}[],
                servicableYear:number,
                yearsRemaining:number,
            }[] = [];


            elem.forEach((element:any,ind:number) => {

                let inflation = element.cashFlow.inflation;
                let tax_rate = element.cashFlow.rawTax;
                let wacc = element.cashFlow.rawWacc;
                let volume = element.cashFlow.volume;
                let month = element.cashFlow.tables.atcc.length;

                let fay = getFormatedYears(element.cashFlow.greatestTimeFrame);
                let fry = getFormatedYears(element.cashFlow.yearsRemaining);
                let sy = fay.years;
                let ry = fry.years;
                if(fay.months !== 0) sy++;
                if(fry.months !== 0) ry++;
    
                let Volume:string[] = Array.from({length:month}).map((elem,ind)=> ind === 0 || ind > sy ? "0" : (volume ? volume.toString() : "0") );
                let Inflation:string[] = Array.from({length:month}).map((elem,ind)=> ind <= 1 || ind > sy ? "0" : (inflation ? inflation.toString() : "0") );
                let Lta:string[] = Array.from({length:month}).map((elem,ind)=> ("0" ) );
                let Tax:string[] = Array.from({length:month}).map((elem,ind)=> (ind > sy ? "0" : (tax_rate ? tax_rate.toString() : "0") ) );
                let Wacc:string[] = Array.from({length:month}).map((elem,ind)=> (ind > sy ? "0" : (wacc ? wacc.toString() : "0" ) ) );
    
                x = [
                    ...x,
                    {
                        name:element.name,
                        data:[
                            {
                                name:'Volume',
                                value:Volume
                            },
                            {
                                name:'Inflation',
                                value:Inflation
                            },
                            {
                                name:'Long term agreement',
                                value:Lta
                            },
                            {
                                name:'Tax Rate',
                                value:Tax
                            },
                            {
                                name:'WACC',
                                value:Wacc
                            }
                        ],
                        servicableYear: sy,
                        yearsRemaining : ry
                    }
                ]
            
            });
            
            localPrep.push(x);
        });
        
        setPrep(localPrep);


    },[myresult])

    const initCompareScenarios = ()=>{
       
        if(compareList.base.length === 0){
            return setError("Base scenario can't be empty !");
        }
        if(compareList.comparable.length === 0){
            return setError("Comparable scenario can't be empty !");
        }

        compare();
    }

    const compare = ()=>{   
        
        let x:any[] = [];

        compareList.comparable.forEach(element => {

            let first = myresult[0][parseInt(compareList.base[0].key)];
            let second = myresult[0][parseInt(element.key)];

            let res = {
                ...first, 
                name:`${first.name} <-> ${second.name}`,
                output:first.output.map((elem:any,index:number)=> ({...elem, data:elem.data.map((el:any,ind:number)=> {

                    if(index === 2 && ind === elem.data.length-1 && (el.value == 11011 || second.output[index].data[ind].value == 11011 ) ){
                        return {...el,value: 11011 }
                    }

                    return {...el,value: parseFloat( ((el.value || 0 )- (second.output[index].data[ind].value || 0)).toFixed(2) ) }
                    
                })}) ),
                cashFlow: {
                    ...first.cashFlow,
                    cavities : first.cashFlow.cavities - second.cashFlow.cavities,
                    irr: first.cashFlow.irr - second.cashFlow.irr,
                    npv : first.cashFlow.npv - second.cashFlow.npv,
                    sellingPrice : first.cashFlow.sellingPrice - second.cashFlow.sellingPrice,
                    tc: first.cashFlow.tc - second.cashFlow.tc,
                    tinv : first.cashFlow.tinv - second.cashFlow.tinv,
                    volume : first.cashFlow.volume - second.cashFlow.volume,
                    tax:[...first.cashFlow.tax], 
                    wacc : [...first.cashFlow.wacc],
                    tables: {
                        ...first.cashFlow.tables,
                        addback: first.cashFlow.tables.addback.map((el:any,ind:number)=> el - (second.cashFlow.tables.addback[ind] || 0)  ),
                        atcc: first.cashFlow.tables.atcc.map((el:any,ind:number)=> el - (second.cashFlow.tables.atcc[ind] || 0)  ),
                        ati: first.cashFlow.tables.ati.map((el:any,ind:number)=> el - (second.cashFlow.tables.ati[ind] || 0)  ),
                        bv: first.cashFlow.tables.bv.map((el:any,ind:number)=> el - (second.cashFlow.tables.bv[ind] || 0)  ),
                        cc: first.cashFlow.tables.cc.map((el:any,ind:number)=> el - (second.cashFlow.tables.cc[ind] || 0)  ),
                        cti: first.cashFlow.tables.cti.map((el:any,ind:number)=> el - (second.cashFlow.tables.cti[ind] || 0)  ),
                        ds: first.cashFlow.tables.ds.map((el:any,ind:number)=> el - (second.cashFlow.tables.ds[ind] || 0)  ),
                        sv: first.cashFlow.tables.sv.map((el:any,ind:number)=> el - (second.cashFlow.tables.sv[ind] || 0)  ),
                        tti: first.cashFlow.tables.tti.map((el:any,ind:number)=> el - (second.cashFlow.tables.tti[ind] || 0)  )
                    }
                    }
            }


            x.push([{...first},{...second},{...res}]);
            
        });
        

        setComparePopup(false);
        setCompared(x);
        setCompareEnabled(true);
    }

    useEffect(()=>{

        console.log("prep",prep);

    },[prep])

    return(
        <div className="outputScreen">
        {
            (!showGraph && !showTable && !compareEnabled && !showAnalysis && !showAmmort) &&
            <WrapperOutput
            name={["Home","Output"]}
            onBackPress={()=> setResult([])}
            leftButtons={[
               <PrintBtn />,
               <Button
               type="text" 
               className={`${Style.rtBtn}`}
               onClick={()=> setShowAmmort(true) }
               >
                   <MonetizationOnIcon />
                   Loans
               </Button>,
               <Button
                type="text" 
                className={`${Style.rtBtn}`}
                onClick={()=> setShowAnalysis(true) }
                >
                    <MonetizationOnIcon />
                    SP Analysis
                </Button>,
                <Button
                type="text" 
                className={`${Style.rtBtn}`}
                onClick={()=> setShowTable(true) }
                >
                    <MonetizationOnIcon />
                    Cash Flows
                </Button>,                
                <Button 
                type="text" 
                className={`${Style.rtBtn}`}
                onClick={()=> setShowGraph(true) }
                >
                    <BarChartIcon />
                    Graph
                </Button>,
                <Button
                type="text" 
                className={`${Style.rtBtn}`}
                onClick={()=> setComparePopup(true) }
                >
                    <CompareIcon />
                    Compare
                </Button>
            ]}
            >
                <div className={`${Style.resHolder} printable`}>
                {
                    myresult.map((aelem,aindex)=>(
                        <ScrollerWrapper>
                    <>

                    {
                        mapper.map((outerElem,outerInd)=>(
                            <div key={outerInd} className={Style.groupcntwrapper}>
                                <div className={Style.groupcnt}>
                                    <div className={Style.gcHeader}>
                                        <p>{outerElem.name}</p>
                                    </div>

                                    <div className={`${Style.gcContent}`}>
                                        <table className={Style.inpTile}>

                                        <tr>

                                            <th>
                                                                               
                                            </th>
                                            {
                                                aelem.map((elem:any,ind:number)=>(
                                                    <th 
                                                    key={`fieldsname-${outerInd}${ind}`}
                                                    >
                                                        <div className={Style.scenarioSelector}>
                                                        {elem.name}
                                                        </div>
                                                    </th>
                                                ))
                                            }
                                        </tr>

                                        {
                                            outerElem.data.map((innerElem,innerInd)=>(
                                                <React.Fragment key={`fieldsname-${outerInd}${innerInd}`}>
                                                    {
                                                        ((outerInd === 0 && innerInd === 1) || (outerInd === 0 && innerInd === 2)) ?
                                                        <></>
                                                        :
                                                        <tr 
                                                    >
                                                        <td>
                                                            <div className={Style.resultInnerLeft}>
                                                                {innerElem}
                                                            </div>
                                                        </td>
                                                    {
                                                        aelem.map((reselem:any,resind:number)=>(
                                                            <td 
                                                            key={`fieldsname-${outerInd}${resind}`}
                                                            >
                                                                <div className={`
                                                                inputCnt
                                                                ${Style.resultBox} 
                                                                ${
                                                                    (outerInd === 0 && innerInd ===0) &&
                                                                    ((
                                                                        (reselem.output[outerInd].data[2].value != 0 && reselem.output[outerInd].data[innerInd].value < reselem.output[outerInd].data[2].value) || 
                                                                        (reselem.output[outerInd].data[1].value != 0 && reselem.output[outerInd].data[innerInd].value > reselem.output[outerInd].data[1].value )
                                                                    )
                                                                        ?
                                                                        "danger"
                                                                        :
                                                                        "green"
                                                                    )
                                                                }
                                                                ${
                                                                    (
                                                                        (outerInd === 0 && innerInd ===7) ||
                                                                        (outerInd === 0 && innerInd ===8) ||
                                                                        (outerInd === 0 && innerInd ===10) ||
                                                                        (outerInd === 0 && innerInd ===12) ||
                                                                        (outerInd === 1 && innerInd ===5) ||
                                                                        (outerInd === 1 && innerInd ===6) ||
                                                                        (outerInd === 1 && innerInd ===8) ||
                                                                        (outerInd === 2 && innerInd ===5) ||
                                                                        (outerInd === 2 && innerInd ===6) ||
                                                                        (outerInd === 2 && innerInd ===8)
                                                                    ) &&
                                                                    (
                                                                        reselem.output[outerInd].data[innerInd].value < 0 ? "danger" : "green"
                                                                    )
                                                                } 

                                                                ${
                                                                    
                                                                    (outerInd === 0 && (innerInd === 9 || innerInd === 11)) && 
                                                                    ((((reselem.output[0].data[innerInd].value/reselem.output[0].data[0].value) * 100 > reselem.cashFlow.atrosRate) ) ?
                                                                    "green"
                                                                    :
                                                                    "danger")
                                                                }
                                                                ${
                                                                    
                                                                    ((outerInd === 1 || outerInd === 2) && (innerInd === 7 || innerInd === 9)) && 
                                                                    ((((reselem.output[0].data[innerInd+2].value/reselem.output[0].data[0].value) * 100 > reselem.cashFlow.atrosRate) ) ?
                                                                    "green"
                                                                    :
                                                                    "danger")
                                                                }

                                                                ${
                                                                    
                                                                    (outerInd === 2 && innerInd ===10) &&
                                                                    (reselem.output[outerInd].data[innerInd].value < reselem.cashFlow.irrRate ? "danger" : "green")
                                                                    
                                                                }

                                                                ${
                                                                    
                                                                    (outerInd === 3 && innerInd ===0) &&
                                                                    (
                                                                        (
                                                                            reselem.output[outerInd].data[innerInd].value > reselem.cashFlow.ot_above_x && 
                                                                            reselem.output[outerInd].data[innerInd].value <= reselem.cashFlow.ot_above_y  
                                                                        )? "danger" :
                                                                        (
                                                                            reselem.output[outerInd].data[innerInd].value > reselem.cashFlow.ot_above_y && 
                                                                            reselem.output[outerInd].data[innerInd].value <= 8500
                                                                        ) ? "superdanger" : 
                                                                        (
                                                                            reselem.output[outerInd].data[innerInd].value > 8500 && "notpossiblevalue"
                                                                        )
                                                                    )
                                                                    
                                                                }

                                                                `}>
                                                                    {
                                                                        reselem.output[outerInd].data[innerInd].type === "currency" &&
                                                                            <CurrencyIconRenderer type={myCurrency} className="currencyIcon" />
                                                                    }
                                                                    
                                                                    {
                                                                        (reselem.output[outerInd].data[innerInd].value) ? 
                                                                            (reselem.output[outerInd].data[innerInd].value != 11011) ?
                                                                                myCurrency === "INR" ?
                                                                                    NumberFormat.toINDstandard((reselem.output[outerInd].data[innerInd].value).toFixed(2))
                                                                                :
                                                                                    NumberFormat.toUSstandard((reselem.output[outerInd].data[innerInd].value).toFixed(2))
                                                                                
                                                                                    
                                                                            :
                                                                                <AllInclusiveIcon />
                                                                        :
                                                                            0
                                                                    }
                                                                   
                                                                    {
                                                                        (
                                                                            reselem.output[outerInd].data[innerInd].type === "perc" && 
                                                                            reselem.output[outerInd].data[innerInd].value != 11011 
                                                                        ) &&
                                                                        <span className="signs">
                                                                        %
                                                                        </span>
                                                                    }
                                                                    </div>
                                                                
                                                            </td>
                                                        ))
                                                    }
                                                    </tr>
                                                    }
                                                </React.Fragment>
                                            ))
                                        }

                                        </table>
                                    </div>

                                </div>
                            </div>
                        ))
                    }

                    </>
                </ScrollerWrapper>
                    ))
                }

                
                </div>
            </WrapperOutput>
        }
        {
            
            (showGraph && !compareEnabled) &&
            <WrapperOutput
            onBackPress={()=> setShowGraph(false) }
            name={["Home","Output","Graphs"]}
            leftButtons={[
                <PrintBtn />
            ]}
            >
                <GraphScreen 
                mapper={mapper.map((aelem,aind)=> ({ ...aelem, data: aelem.data.filter((belem,bind)=> (aind === 0 && (bind === 1 || bind === 2) ) ? false : true ) }) )}
                myresult={ myresult.map((elem,ind)=>  elem.map((el:any,ii:number)=> ({...el,output: el.output.map((inner:any,innerInd:number)=> ( {...inner,data:inner.data.filter((sin:any,sinInd:number)=> (innerInd === 0 && (sinInd === 1 || sinInd === 2)) ? false : true )} ) ) }) ) ) }
                />
            </WrapperOutput>
            
            
        }
        {
            (showTable && !compareEnabled) &&
            <WrapperOutput
            onBackPress={()=> setShowTable(false) }
            name={["Home","Output","Cash Flows"]}
            leftButtons={[
                <PrintBtn />
            ]}
            >
                <TableScreen
                    myresult={myresult} 
                    prep={prep}
                    setPrep={setPrep}
                    submitableScenario={submitableScenario}
                />
            </WrapperOutput>
        }
        {
            (showAnalysis && !compareEnabled) &&
            <WrapperOutput
            onBackPress={()=> setShowAnalysis(false) }
            name={["Home","Output","SP Analysis"]}
            leftButtons={[
                <PrintBtn />
            ]}
            >
    
                <AnalysisScreen
                myresult={myresult[0]}
                />
            </WrapperOutput>
        }
        {
            ((showAmmort && !selectedLoan) && !compareEnabled) &&
            <WrapperOutput
            onBackPress={()=> setShowAmmort(false) }
            name={["Home","Output","Loans"]}
            leftButtons={[
         
            ]}
            >
                
                <LoanScreen result={result} myCurrency={myCurrency} selectedLoan={selectedLoan} setSelectedLoan={setSelectedLoan} setSelectedLoanResult={setSelectedLoanResult} />
                
            </WrapperOutput>
        }
        {
            ((showAmmort && selectedLoan) && !compareEnabled) &&
            <WrapperOutput
            onBackPress={()=> setSelectedLoan("") }
            name={["Home","Output","Loans",LoanNameFormatter.format(selectedLoan)]}
            leftButtons={[
         
            ]}
            >
                
                <SingleLoanScreen result={selectedLoanResult} myCurrency={myCurrency} selectedLoan={selectedLoan}  />
                
            </WrapperOutput>
        }
        {
            compareEnabled &&
            <ComparedScreen 
            result={compared}
            setCompareEnabled={setCompareEnabled}
            myCurrency={myCurrency}
            mapper={mapper}
            submitableScenario={submitableScenario}
            />
        }
        {
            comparePopup &&
            <Modal
            className="modal"
            open={comparePopup}
            onClose={()=> {
                setComparePopup(false);
            }}
            >
    
            <div className="modalPopUp">

                <CloseOutlined
                className="popupClose" 
                onClick={()=> {
                    setComparePopup(false);
                }}
                />
                <Flynaut />

                <DragDropContext onDragEnd={onDragEnd}>

                <div className={Style.cmpListCnt}>
                        <div className={Style.cmpListTop}>
                        
                            <div className={Style.listCnt}>
                                <p>Options</p>
                                <Droppable droppableId="scenarios" type="scenarios">
                                    {(provided, snapshot) => {
                                    return (
                                        <div
                                        className={Style.mainListCnt}
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        >
                                        {
                                            compareList.scenario?.map((elem,ind)=>(
                                                <Draggable key={elem.key} draggableId={`${elem.key}`} index={ind}>
                                                    {(provided, snapshot) => {
                                                        return (
                                                        <div
                                                        className={Style.listTileCnt}
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                
                                                            <div className={Style.listTile}>
                                                            {elem.name}
                                                            </div>
                                                            
                                                        
                                                        </div>
                                                        );
                                                    }}
                                                    </Draggable>
                                            ))
                                        }
                                        {provided.placeholder}
                                        </div>
                                    );
                                    }}
                                </Droppable>
                            </div>
                    

                        </div>
                        <div className={Style.cmpListBot}>

                            <div className={Style.listCnt}>

                                <p>Base Scenario</p>
                                <Droppable  droppableId="base" type="scenarios">
                                    {(provided, snapshot) => {
                                    return (
                                        <div
                                        className={Style.mainListCnt}
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        >
                                        {
                                            compareList.base?.map((elem,ind)=>(
                                                <Draggable key={elem.key} draggableId={`${elem.key}`} index={ind}>
                                                    {(provided, snapshot) => {
                                                        return (
                                                        <div
                                                        className={Style.listTileCnt}
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                        
                                                        <div className={Style.listTile}>
                                                                {elem.name}
                                                            </div>
                                                        
                                                        </div>
                                                        );
                                                    }}
                                                    </Draggable>
                                            ))
                                        }
                                        {provided.placeholder}
                                        </div>
                                    );
                                    }}
                                </Droppable>

                            </div>
                            <div className={Style.listCnt}>
                                <p>Comparable Scenario</p>
                                <Droppable droppableId="comparable" type="scenarios">
                                    {(provided, snapshot) => {
                                    return (
                                        <div
                                        className={Style.mainListCnt}
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        >
                                        {
                                            compareList.comparable?.map((elem,ind)=>(
                                                <Draggable key={elem.key} draggableId={`${elem.key}`} index={ind}>
                                                    {(provided, snapshot) => {
                                                        return (
                                                        <div
                                                        className={Style.listTileCnt}
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                        
                                                            <div className={Style.listTile}>
                                                                {elem.name}
                                                            </div>
                                                        
                                                        </div>
                                                        );
                                                    }}
                                                    </Draggable>
                                            ))
                                        }
                                        {provided.placeholder}
                                        </div>
                                    );
                                    }}
                                </Droppable>
                            </div>

                        </div>
                    </div>
                </DragDropContext>
                
                <div className="mpuBot">

                <Button className="histbtn-blue" type="text" onClick={initCompareScenarios}>
                    Compare <RightOutlined />
                </Button>

                </div>
            </div>      
            </Modal>
        }
        {
            error &&
            <Modal
            open={error ? true : false}
            onClose={()=> {
                setError("");
            }}
            >
                <ModalBox
                open={error ? true : false}
                onClose={()=> setError("") }
                header="Restricted !"
                message={error} 
                onOk={()=>{
                    setError("")
                }}
                />
            </Modal>
        }
        </div>
    )
}

export default memo(OutputScreen);